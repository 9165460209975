import React, { ReactElement } from "react";

export default function Handshake({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_2023"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_84_2023)">
                <path d="M4.908 17.88l-1.487-1.228a4.61 4.61 0 01-.662-6.483L7.22 4.735l5.078 4.14-7.39 9.004zM7.377 6.831l-3.472 4.274a3.124 3.124 0 00.453 4.394l.35.29 5.457-6.69-2.788-2.268z"></path>
                <path d="M26.953 19.275a2.973 2.973 0 01-1.866-.669l-2.156-1.754a5.829 5.829 0 01-7.643.327.744.744 0 01-.104-1.048.743.743 0 011.04-.105 4.336 4.336 0 006.082-.624l.468-.573 3.272 2.662a1.487 1.487 0 002.074-.208l.127-.164-4.915-4c-2.148-1.754-6.349-2.646-8.921-1.137l-.446.26-3.22-2.646a.745.745 0 11.945-1.153l2.423 1.97c3.153-1.487 7.702-.483 10.163 1.487l6.066 4.944-1.07 1.309a2.974 2.974 0 01-2.32 1.122zM35.613 16.904l-8.758-7.16 4.142-5.077 6.059 4.936a3.481 3.481 0 01.498 4.892l-1.941 2.409zm-6.691-7.346l6.453 5.257.996-1.227a1.999 1.999 0 00-.282-2.803L31.212 6.77l-2.29 2.788z"></path>
                <path d="M22.975 35.765a1.972 1.972 0 01-1.264-.453l-.49-.394a2.014 2.014 0 01-.29-2.833l4.356-5.345a.746.746 0 011.068-.137.744.744 0 01.084 1.074l-4.357 5.352a.496.496 0 00-.118.38.49.49 0 00.193.349l.49.402a.523.523 0 00.744-.075l7.606-9.353a8.417 8.417 0 001.546-6.066v-.305l2.483-3.048a.745.745 0 111.152.944l-2.096 2.572a9.835 9.835 0 01-1.926 6.848l-7.62 9.345a2.044 2.044 0 01-1.353.743h-.208z"></path>
                <path d="M19.718 34.746c-.461 0-.908-.16-1.264-.453l-.49-.394a2.008 2.008 0 01-.29-2.825l5.16-6.334h-.001a.744.744 0 111.145.936l-5.152 6.372a.527.527 0 00.074.743l.49.394a.52.52 0 00.744-.074l.803-.982a.745.745 0 011.152.945l-.802.98a2.008 2.008 0 01-1.569.692zM14.997 31.305a.743.743 0 01-.468-.171l-1.487-1.197a2.006 2.006 0 01-.29-2.826l3.791-4.66a.743.743 0 111.153.936l-3.77 4.624a.527.527 0 00.075.744l1.487 1.197a.744.744 0 01-.491 1.353z"></path>
                <path d="M16.47 33.728c-.462 0-.91-.157-1.27-.446l-.491-.402a2.016 2.016 0 01-.744-1.353 1.993 1.993 0 01.446-1.486l5.948-7.316a.744.744 0 011.152.937l-5.948 7.315a.514.514 0 00.075.744l.49.401c.227.181.557.148.744-.074l.796-.982a.76.76 0 011.16.982l-.796.944a2 2 0 01-1.354.744l-.208-.008zM5.199 13.62a.743.743 0 01-.692-1.027.744.744 0 011.212-.238c.14.138.22.325.223.52a.745.745 0 01-.743.744zM34.794 12.65a.743.743 0 01-.743-.743.743.743 0 01.06-.283.86.86 0 01.156-.245.743.743 0 01.817-.156c.089.036.17.09.238.156.07.07.125.154.164.245.035.09.053.186.052.283a.743.743 0 01-.216.528.739.739 0 01-.238.156.807.807 0 01-.29.06z"></path>
            </g>
        </svg>
    );
}
