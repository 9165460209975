import React, { ReactElement } from "react";

export default function LightBulb({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_1979"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_84_1979)">
                <path d="M19.994 7.429a.5.5 0 01-.5-.5v-3.93a.5.5 0 111 0v3.93a.5.5 0 01-.5.5zM12.96 9.314a.5.5 0 01-.432-.25L10.563 5.66a.5.5 0 01.866-.5l1.964 3.403a.5.5 0 01-.432.75zM7.812 14.46a.496.496 0 01-.25-.067L4.16 12.428a.5.5 0 01.5-.865l3.402 1.964a.5.5 0 01-.25.933zM5.929 21.493h-3.93a.5.5 0 110-1h3.93a.5.5 0 110 1zM4.41 30.49a.499.499 0 01-.25-.933l3.403-1.965a.5.5 0 01.5.866L4.66 30.423a.499.499 0 01-.25.067zM35.576 30.49a.497.497 0 01-.25-.067l-3.402-1.965a.5.5 0 01.5-.866l3.402 1.965a.5.5 0 01-.25.933zM37.986 21.493h-3.93a.5.5 0 110-1h3.93a.5.5 0 110 1zM32.174 14.46a.499.499 0 01-.25-.932l3.402-1.965a.5.5 0 01.5.866l-3.403 1.964a.498.498 0 01-.249.067zM27.025 9.314a.5.5 0 01-.433-.75l1.965-3.403a.5.5 0 11.866.5l-1.965 3.402a.5.5 0 01-.433.25zM20.445 24.935h-.881a2.888 2.888 0 01-1.937-.745.5.5 0 01.67-.742c.348.314.799.487 1.267.487h.881a1.222 1.222 0 000-2.442h-.858c-1.188 0-2.181-.907-2.261-2.064a2.225 2.225 0 012.215-2.378h.882c.716 0 1.405.265 1.937.745a.5.5 0 01-.67.742 1.892 1.892 0 00-1.268-.487h-.88a1.226 1.226 0 00-1.218 1.31c.043.634.598 1.132 1.263 1.132h.858c.595 0 1.153.232 1.571.652a2.223 2.223 0 01-1.57 3.79z"></path>
                <path d="M19.994 18.05a.5.5 0 01-.5-.5v-1.232a.5.5 0 111 0v1.232a.5.5 0 01-.5.5zM19.994 26.167a.5.5 0 01-.5-.5v-1.232a.5.5 0 111 0v1.232a.5.5 0 01-.5.5zM25.964 17.506a.5.5 0 01-.416-.221 6.704 6.704 0 00-2.876-2.412.5.5 0 01.402-.916 7.706 7.706 0 013.305 2.77.5.5 0 01-.415.78zM26.901 19.533a.5.5 0 01-.48-.364 6.536 6.536 0 00-.074-.243.5.5 0 01.95-.31c.03.093.06.187.086.28a.5.5 0 01-.482.637z"></path>
                <path d="M24.043 31.506a.5.5 0 01-.5-.5v-.835c0-.728.382-1.4 1.022-1.797 2.926-1.816 4.473-5.08 4.036-8.517-.496-3.912-3.705-7.062-7.63-7.49-2.506-.275-4.911.494-6.771 2.162a8.695 8.695 0 00-2.888 6.464c0 3.03 1.54 5.792 4.12 7.387a2.098 2.098 0 011.01 1.788v.837a.5.5 0 11-.999 0v-.837c0-.38-.201-.73-.538-.938a9.627 9.627 0 01-4.592-8.237 9.696 9.696 0 013.22-7.209c2.074-1.86 4.755-2.716 7.547-2.412 4.379.478 7.96 3.993 8.513 8.359.486 3.83-1.24 7.467-4.501 9.492a1.12 1.12 0 00-.55.947v.835a.5.5 0 01-.5.5h.001z"></path>
                <path d="M24.112 34.74h-8.24a2.12 2.12 0 01-2.116-2.117c0-1.167.95-2.117 2.117-2.117h8.24c1.167 0 2.117.95 2.117 2.117a2.12 2.12 0 01-2.117 2.117zm-8.24-3.234a1.118 1.118 0 000 2.234h8.241a1.118 1.118 0 000-2.234h-8.24z"></path>
                <path d="M20.828 37.66h-1.67a3.425 3.425 0 01-3.42-3.42.5.5 0 111 0 2.424 2.424 0 002.42 2.421h1.67a2.424 2.424 0 002.42-2.421.5.5 0 111 0 3.424 3.424 0 01-3.42 3.42z"></path>
            </g>
        </svg>
    );
}
