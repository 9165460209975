import Modal from "@components/shared/modal";
import CloseIcon from "@components/shared/modal/closeIcon";
import { DomainConfig, HotTortsItem } from "@genericTypes/sharedTypes";
import { GQLDomainQuery } from "@gql/types/gql";
import { showContactModal, showModal } from "@store/categoryStore";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import Link from "next/link";
import React, { ReactElement, useState } from "react";
import Portal from "src/portals/Portal";
import styles from "./styles.module.scss";

export default function HotTorts(): ReactElement {
    const { data: domainInfo } = useQuery<GQLDomainQuery, Error>({
        queryKey: ["domain"],
    });
    const [modalVisibility, setModalVisibility] = useAtom(showModal);
    const [activeItem, setActiveItem] = useState<HotTortsItem | null>(null);
    const [, setContactModalVisibility] = useAtom(showContactModal);

    const domainConfig = domainInfo?.domain?.config as DomainConfig;

    return (
        <section
            className={`${styles["hot-torts-section"]} main-container relative px-4 py-8 md:p-16 text-center`}
            id="hot_torts"
        >
            <h2 className=" text-4xl mb-10 md:mb-20">Hot Torts</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                {domainConfig.hot_torts?.map((item) => (
                    <div
                        key={item.name}
                        className="bg-[#292929] text-white  px-10 lg:px-5 py-8 flex flex-col items-center"
                        onClick={() => {
                            setActiveItem(item);
                        }}
                    >
                        <img
                            src={item.icon}
                            className="w-20 h-20 mx-auto mb-8"
                            alt={item.name}
                        />
                        <p className="text-xl font-medium md:text-3xl mb-5">
                            {item.name}
                        </p>
                        <p
                            className="font-light"
                            dangerouslySetInnerHTML={{
                                __html: item.brief ?? "",
                            }}
                        />
                        <Link href={`/${item.slug as string}/signed-retainers`}>
                            <a
                                // onClick={() =>
                                //     setModalVisibility((prev) => !prev)
                                // }
                                className="underline mt-10"
                            >
                                Get Signed Retainers
                            </a>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="my-16 flex justify-center items-center">
                <button
                    onClick={() => setContactModalVisibility((prev) => !prev)}
                    className={`${styles["button"]}  text-xl`}
                >
                    Request a Custom Quote
                </button>
            </div>

            {modalVisibility && (
                <Portal>
                    <Modal
                        onModalClose={() => {
                            setModalVisibility(false);
                        }}
                        backdropClassName="bg-opacity-50 bg-black flex items-center justify-center"
                        className="bg-white text-left overflow-hidden shadow-xl p-8 w-full m-4 max-w-2xl"
                    >
                        <div>
                            <div className="border-b pb-1 mb-5 flex justify-between">
                                <h2 className="text-3xl font-medium">
                                    Criteria
                                </h2>
                                <button
                                    onClick={() => {
                                        setModalVisibility((prev) => !prev);
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: activeItem?.criteria as string,
                                }}
                                className={styles["modal-content"]}
                            ></div>
                            <div className="flex items-center justify-center mt-8">
                                <button
                                    onClick={() => {
                                        setModalVisibility((prev) => !prev);
                                    }}
                                    className={`${styles["button"]} uppercase text-xl`}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div>
                    </Modal>
                </Portal>
            )}
        </section>
    );
}
