import { showContactModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function Hero(): ReactElement {
    const [, setModalVisibility] = useAtom(showContactModal);
    return (
        <section className={`${styles["hero-section"]} h-screen -z-10`}>
            <div className="text-center main-container text-white flex flex-col gap-10 items-center p-4">
                <h1 className="text-3xl md:text-5xl font-semibold md:leading-[normal]">
                    We Find Clients for Lawyers: Tailored Legal Lead Solutions
                </h1>
                <h2 className="text-[#ACACAC] text-base md:text-xl  leading-[160%] w-full md:w-2/3">
                    We are highly qualified engineers and marketers who
                    specialize in risk-free mass tort case acquisition. We
                    generate all our leads in-house, qualify them, and then we
                    send it to reputable intake centers to sign retainers
                </h2>
                <button
                    onClick={() => setModalVisibility((prev) => !prev)}
                    className={`${styles["button"]}  text-xl`}
                >
                    Get More Cases
                </button>
            </div>
        </section>
    );
}
