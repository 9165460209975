import React, { ReactElement } from "react";

export default function PaperCheck({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_1999"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_84_1999)">
                <path d="M23.979 27.13a9.01 9.01 0 00-.538-2.556 6.054 6.054 0 00-.166-.435c-.06-.15-.132-.299-.2-.444a9.072 9.072 0 00-1.78-2.491.542.542 0 00-.073-.064 8.48 8.48 0 00-1.041-.88 9.321 9.321 0 00-1.831-1.036 9.263 9.263 0 00-6.679-.192c-.175.055-.346.123-.516.192a9.17 9.17 0 00-2.945 1.984A9.192 9.192 0 005.5 27.75c0 1.245.247 2.458.725 3.597a9.153 9.153 0 001.985 2.94c.85.85 1.835 1.52 2.945 1.984 1.14.486 2.35.73 3.597.73a9.19 9.19 0 003.598-.73 9.1 9.1 0 002.944-1.984c.047-.047.099-.103.141-.15.273-.277.525-.571.747-.879a9.158 9.158 0 001.81-5.235c.008-.09.008-.18.008-.269 0-.218-.008-.423-.021-.623zm-1.071.88a7.99 7.99 0 01-.64 2.905 8.09 8.09 0 01-1.75 2.595 8.076 8.076 0 01-2.595 1.75c-2.014.848-4.34.848-6.354 0a8.048 8.048 0 01-2.595-1.75 8.163 8.163 0 01-2.394-5.77c0-1.105.218-2.171.644-3.178a8.186 8.186 0 014.345-4.344.622.622 0 01.094-.035c.286-.119.58-.221.879-.298a8.113 8.113 0 012.202-.303c1.1 0 2.172.217 3.175.64.025.012.051.021.08.034a8.051 8.051 0 012.514 1.711c.521.52.96 1.097 1.32 1.728.161.278.302.568.43.866 0 .005.004.009.004.013a8.103 8.103 0 01.64 3.166c.005.09.005.184 0 .27z"></path>
                <path d="M23.979 27.13a9.01 9.01 0 00-.538-2.556 6.054 6.054 0 00-.166-.435c-.06-.15-.132-.299-.2-.444a9.072 9.072 0 00-1.78-2.491.542.542 0 00-.073-.064 8.48 8.48 0 00-1.041-.88 9.321 9.321 0 00-1.831-1.036 9.263 9.263 0 00-6.679-.192c-.175.055-.346.123-.516.192a9.17 9.17 0 00-2.945 1.984A9.192 9.192 0 005.5 27.75c0 1.245.247 2.458.725 3.597a9.153 9.153 0 001.985 2.94c.85.85 1.835 1.52 2.945 1.984 1.14.486 2.35.73 3.597.73a9.19 9.19 0 003.598-.73 9.1 9.1 0 002.944-1.984c.047-.047.099-.103.141-.15.273-.277.525-.571.747-.879a9.158 9.158 0 001.81-5.235c.008-.09.008-.18.008-.269 0-.218-.008-.423-.021-.623zm-1.071.88a7.99 7.99 0 01-.64 2.905 8.09 8.09 0 01-1.75 2.595 8.076 8.076 0 01-2.595 1.75c-2.014.848-4.34.848-6.354 0a8.048 8.048 0 01-2.595-1.75 8.163 8.163 0 01-2.394-5.77c0-1.105.218-2.171.644-3.178a8.186 8.186 0 014.345-4.344.622.622 0 01.094-.035c.286-.119.58-.221.879-.298a8.113 8.113 0 012.202-.303c1.1 0 2.172.217 3.175.64.025.012.051.021.08.034a8.051 8.051 0 012.514 1.711c.521.52.96 1.097 1.32 1.728.161.278.302.568.43.866 0 .005.004.009.004.013a8.103 8.103 0 01.64 3.166c.005.09.005.184 0 .27z"></path>
                <path d="M20.5 24.93L13.453 32 9 27.538l1.425-1.43 3.028 3.039 2-2.007 2.556-2.564 1.07-1.075 1.421 1.43z"></path>
                <path d="M33.613 7.977L28.57 2.999H11.5v15.568a7.25 7.25 0 01.784-.228c.034-.009.064-.017.103-.021V3.87h15.815l.107.106V9.33h5.304v23.793H22.597c-.056.09-.12.174-.184.263a6.727 6.727 0 01-.501.613H34.5V8.848l-.887-.871zm-4.422.478V4.847l3.655 3.608h-3.655z"></path>
                <path d="M14.5 13.5h11v1h-11v-1zM14.5 17.001h17v.5h-17v-.5zM31.5 20.5v.999h-9.584c-.079-.097-.154-.19-.237-.286a.366.366 0 00-.063-.068 8.28 8.28 0 00-.616-.646h10.5zM31.5 23.5v1h-7.659a7.54 7.54 0 00-.224-.684c-.033-.107-.075-.208-.117-.316h8zM29 27.5v1h-4.991c.004-.102.004-.204.004-.306 0-.238-.004-.461-.013-.694h5z"></path>
            </g>
        </svg>
    );
}
