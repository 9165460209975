import React, { ReactElement } from "react";

export default function Cup({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
            className={className}
        >
            <mask
                id="mask0_0_1455"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g mask="url(#mask0_0_1455)">
                <path
                    fill="#000"
                    d="M28.886 64.353h22.96c1.702 0 2.66.653 3.397 1.76.514.759.618 1.91.779 2.99H24.716c.161-1.08.267-2.231.774-2.99.742-1.106 1.7-1.76 3.396-1.76zM67.103 20.18c2.638.053 4.696 1.804 4.707 4.11.025 7.05-4.283 13.536-9.853 18.54a45.57 45.57 0 01-3.715 2.974c1.105-2.263 1.747-4.848 2.124-7.752l1.658-14.233c.656-2.404 2.876-3.686 5.08-3.638zm-53.476 0c2.438.053 4.62 1.446 5.082 3.648l1.654 14.202c.335 2.89.965 5.46 2.052 7.716a45.751 45.751 0 01-3.638-2.916c-5.57-5.004-9.988-11.49-9.85-18.541.052-2.717 2.344-4.161 4.7-4.109zm6.312-8.727h40.85l-3.062 26.252c-.523 3.968-1.492 6.998-3.371 9.322-1.863 2.318-4.69 4.057-9.22 5.276a1.33 1.33 0 00-1.04 1.68v7.706h-7.465v-7.706c0-1.05-.443-1.516-1.042-1.68-4.53-1.219-7.381-2.963-9.26-5.276-1.89-2.324-2.864-5.335-3.323-9.3l-3.067-26.274zM18.453 8.8a1.33 1.33 0 00-1.324 1.481l.989 8.464c-1.357-.837-2.937-1.225-4.497-1.22-1.791.006-3.585.525-4.994 1.66-1.403 1.135-2.361 2.937-2.361 5.105 0 8.12 4.827 15.2 10.736 20.514 5.528 4.968 11.992 8.422 16.963 9.803v7.081h-5.08c-2.502 0-4.466 1.246-5.606 2.95-1.142 1.7-1.581 3.785-1.581 5.79a1.33 1.33 0 001.329 1.328H57.7c.738 0 1.33-.596 1.33-1.328 0-2.005-.441-4.089-1.58-5.79-1.136-1.702-3.105-2.95-5.607-2.95H46.76v-7.08c4.973-1.383 11.437-4.837 16.965-9.804C69.64 39.491 74.46 32.41 74.46 24.29c0-2.167-.951-3.968-2.361-5.104-1.403-1.137-3.197-1.654-4.987-1.66-1.56-.006-3.142.382-4.498 1.219l.984-8.464a1.325 1.325 0 00-1.32-1.481H18.454z"
                ></path>
                <path
                    fill="#DD1F26"
                    d="M40.366 22.949l2.73 4.597c.19.314.499.539.855.618l5.172 1.178-3.627 3.931c-.262.283-.384.66-.346 1.042l.601 5.956-4.748-2.597a1.316 1.316 0 00-1.277 0l-4.748 2.597.603-5.956a1.333 1.333 0 00-.34-1.042l-3.629-3.93 5.172-1.179c.356-.08.665-.304.855-.618l2.727-4.597zm-.09-3.927a1.323 1.323 0 00-1.052.645l-3.586 6.03-6.863 1.564a1.333 1.333 0 00-.681 2.2l4.785 5.176-.801 7.872c-.116 1.074 1.026 1.826 1.968 1.302l6.319-3.46 6.32 3.46a1.324 1.324 0 001.963-1.302l-.798-7.872 4.79-5.177a1.335 1.335 0 00-.687-2.199l-6.863-1.564-3.58-6.03a1.323 1.323 0 00-1.234-.645z"
                ></path>
            </g>
        </svg>
    );
}
