import React, { ReactElement } from "react";

export default function Speaker({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
            className={className}
        >
            <mask
                id="a2"
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#d9d9d9" d="M0 0h80v80H0z"></path>
            </mask>
            <g mask="url(#a2)">
                <path
                    fill="#000"
                    d="M57.913 33.086a6.26 6.26 0 002.694-3.702 6.342 6.342 0 00-.622-4.787c-1.676-2.942-5.352-3.997-8.292-2.427l-5.591-9.813a5.53 5.53 0 00-3.374-2.618 5.456 5.456 0 00-4.215.557 5.584 5.584 0 00-2.583 3.42 5.672 5.672 0 00.103 3.273c-2.655 6.782-8.277 13.698-15.909 19.599a1.297 1.297 0 00-1.385-.047l-7.135 4.173c-2.4 1.405-4.12 3.681-4.842 6.41a10.605 10.605 0 001.03 8.005 10.394 10.394 0 009.036 5.274c1.766 0 3.555-.454 5.19-1.412l.408-.238 7.869 10.409a3.03 3.03 0 002.192 1.224 3.011 3.011 0 002.373-.806l2.167-1.992a3.168 3.168 0 00.386-4.248l-7.399-9.85c8.788-3.682 17.43-5.125 24.498-4.072a5.54 5.54 0 004.19 1.922c.943 0 1.896-.242 2.769-.752 2.652-1.552 3.565-5.002 2.033-7.69zm-.201-7.16c.48.845.609 1.828.36 2.77a3.622 3.622 0 01-1.473 2.084l-3.592-6.305a3.582 3.582 0 014.705 1.451zM16.824 57.745a7.777 7.777 0 01-6.76-3.945 7.925 7.925 0 01-.766-5.987c.54-2.043 1.825-3.746 3.619-4.795l5.998-3.51 7.79 13.672-5.998 3.509a7.662 7.662 0 01-3.883 1.056zm18.44 7.875l-2.167 1.992a.435.435 0 01-.373.127.433.433 0 01-.344-.193l-7.66-10.133 3.433-2.008 7.172 9.548a.497.497 0 01-.061.667zm-6.655-14.417l-7.052-12.375c7.346-5.647 12.955-12.243 16.055-18.854l15.139 26.569c-7.202-.586-15.643 1.038-24.142 4.66zm31.55-2.917a2.912 2.912 0 01-4.002-1.087L38.753 16.657c-.808-1.418-.327-3.238 1.073-4.056a2.86 2.86 0 011.451-.395c.256 0 .516.034.77.103.76.206 1.392.695 1.781 1.378L61.231 44.23c.808 1.419.327 3.237-1.071 4.056z"
                ></path>
                <g fill="#dd1f26">
                    <path d="M62.066 21.845a1.339 1.339 0 00-.48 1.816 1.31 1.31 0 001.138.666c.222 0 .448-.058.655-.179l5.268-3.082c.629-.367.844-1.18.481-1.816a1.303 1.303 0 00-1.793-.487zM58.72 9.465l-3.208 5.632a1.34 1.34 0 00.48 1.816c.206.12.431.178.654.178a1.31 1.31 0 001.139-.665l3.208-5.631a1.34 1.34 0 00-.48-1.817 1.303 1.303 0 00-1.793.487zM72.288 33.274c.724 0 1.312-.596 1.312-1.33s-.588-1.33-1.312-1.33H65.87a1.32 1.32 0 00-1.312 1.33c0 .734.587 1.33 1.312 1.33z"></path>
                </g>
            </g>
        </svg>
    );
}
