import React, { ReactElement } from "react";

export default function Bars({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="a"
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#d9d9d9" d="M0 0h40v40H0z"></path>
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#fff"
                    d="M32.739 13.455h-5.405a.74.74 0 00-.735.746v20.22c0 .411.33.745.735.745h5.405a.74.74 0 00.735-.746V14.2a.74.74 0 00-.735-.745zm-.735 20.22h-3.935V14.946h3.935zm-9.3-14.463h-5.406a.74.74 0 00-.735.746v14.463c0 .412.33.746.735.746h5.405a.74.74 0 00.735-.747V19.958a.74.74 0 00-.735-.745zm-.736 14.463h-3.935V20.703h3.936zm-9.301-8.95H7.262a.74.74 0 00-.735.746v8.95c0 .411.33.745.735.745h5.405a.74.74 0 00.735-.746v-8.95a.74.74 0 00-.735-.745zm-.735 8.95H7.997v-7.46h3.935zM27.724 6.308a.742.742 0 01.694-.785l3.414-.188a.727.727 0 01.583.241c.149.165.217.39.185.61l-.493 3.434a.739.739 0 01-.833.63.744.744 0 01-.621-.844l.193-1.351c-6.252 5.59-13.697 9.937-22.152 12.925a.736.736 0 01-.936-.459.748.748 0 01.452-.949c8.274-2.924 15.554-7.172 21.663-12.636l-1.375.076a.74.74 0 01-.774-.705z"
                ></path>
            </g>
        </svg>
    );
}
