import React, { ReactElement } from "react";

export default function Notes({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_2011"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g mask="url(#mask0_84_2011)">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M12.576 2.5c-1.21 0-2.207.991-2.207 2.205v.227H9.305a2.826 2.826 0 00-2.82 2.82V34.68c0 1.553 1.27 2.82 2.82 2.82h20.891c1.55 0 2.82-1.267 2.82-2.82V7.751a2.827 2.827 0 00-2.82-2.819h-1.064v-.227A2.215 2.215 0 0026.924 2.5c-1.21 0-2.208.991-2.208 2.205v.227h-2.757v-.227a2.216 2.216 0 00-2.21-2.205c-1.21 0-2.207.991-2.207 2.205v.227h-2.758v-.227A2.215 2.215 0 0012.576 2.5zm0 1.207a.987.987 0 011.007.998v1.662a.99.99 0 01-1.007 1.001.99.99 0 01-1.006-1.001V4.705a.987.987 0 011.006-.998zm7.174 0a.987.987 0 011.006.998v1.662a.99.99 0 01-1.006 1.001.99.99 0 01-1.007-1.001v-.829V4.705a.987.987 0 011.007-.999zm7.174 0a.987.987 0 011.007.998v1.662a.99.99 0 01-1.007 1.001.99.99 0 01-1.006-1.001V4.705a.987.987 0 011.006-.998zM9.304 6.139h1.064v.227c0 1.214.998 2.209 2.208 2.209 1.21 0 2.208-.995 2.208-2.209V6.14h2.758v.227c0 1.214.998 2.209 2.208 2.209 1.21 0 2.209-.995 2.209-2.209V6.14h2.756v.227c0 1.214 1 2.209 2.21 2.209s2.207-.995 2.207-2.209V6.14h1.064c.91 0 1.618.711 1.618 1.612V34.68c0 .901-.708 1.612-1.618 1.612H9.304c-.91 0-1.618-.711-1.618-1.612V7.751c0-.9.708-1.612 1.618-1.612zm7.292 5.285a.598.598 0 00-.429.162l-2.824 2.668-1.193-1.256a.6.6 0 00-1.037.401.605.605 0 00.168.432l1.606 1.687c.228.24.606.25.847.023l3.254-3.073a.603.603 0 00-.392-1.044zm3.078 1.538a.601.601 0 00-.424 1.029.6.6 0 00.424.178h8.11a.599.599 0 00.603-.606.605.605 0 00-.603-.6h-8.11zM16.597 20a.598.598 0 00-.43.165l-2.824 2.663-1.193-1.256a.6.6 0 00-.85-.018.605.605 0 00-.02.851l1.607 1.688a.599.599 0 00.847.027l3.254-3.079a.605.605 0 00-.39-1.041zm3.077 1.537a.6.6 0 00-.598.605c.001.332.269.6.598.601h8.11a.6.6 0 00.603-.6.606.606 0 00-.603-.607h-8.11zm-3.077 7.036a.598.598 0 00-.43.165l-2.824 2.663-1.193-1.255a.6.6 0 00-1.037.405.605.605 0 00.168.432l1.606 1.688c.228.24.606.25.847.023l3.254-3.074a.604.604 0 00.026-.855.599.599 0 00-.417-.192zm3.077 1.541a.602.602 0 000 1.204h8.11a.6.6 0 100-1.204h-8.11z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}
