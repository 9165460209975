import React, { ReactElement } from "react";

export default function Computer({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_2047"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_84_2047)">
                <path d="M34.514 3.685H4.811a.486.486 0 00-.486.486v26.544c0 .269.218.486.486.486h10.639v2.317h-1.325a3.29 3.29 0 00-3.27 2.916.486.486 0 00.484.542h16.646a.485.485 0 00.483-.541 3.29 3.29 0 00-3.27-2.917h-1.325V31.2h10.64a.486.486 0 00.485-.486V4.171a.486.486 0 00-.486-.486zM25.2 34.49c.989 0 1.845.622 2.175 1.515H11.952a2.32 2.32 0 012.174-1.515H25.2zm-8.777-.972v-2.316h6.48v2.316h-6.48zm17.605-3.288H5.298v-3.25h28.73v3.25zm0-4.222H5.298V4.657h28.73v21.35z"></path>
                <path d="M18.293 29.022h2.74a.486.486 0 100-.972h-2.74a.486.486 0 100 .972zM8.603 23.06h22.119a.486.486 0 100-.972h-.95V10.315a.486.486 0 00-.486-.486h-4.46a.486.486 0 00-.486.486v11.773h-1.961v-8.046a.486.486 0 00-.486-.486h-4.46a.486.486 0 00-.487.486v8.046h-1.96v-3.44a.486.486 0 00-.487-.486h-4.46a.486.486 0 00-.486.486v3.44h-.95a.486.486 0 100 .971zM25.312 10.8H28.8v11.287h-3.488V10.801zm-7.393 3.728h3.488v7.56H17.92v-7.56zm-7.394 4.606h3.489v2.954h-3.489v-2.954z"></path>
            </g>
        </svg>
    );
}
