import Modal from "@components/shared/modal";
import CloseIcon from "@components/shared/modal/closeIcon";
import { showContactModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import React, { ReactElement } from "react";
import Portal from "src/portals/Portal";
import ContactUsForm, { Field } from "../contactUsForm";
import styles from "./styles.module.scss";

export default function ContactUsModal(): ReactElement {
    const [modalVisibility, setModalVisibility] = useAtom(showContactModal);

    const fields = [
        {
            name: "firmName",
            label: "Firm Name",
            component: "input",
        },
        {
            name: "firstName",
            label: "First Name",
            component: "input",
        },
        {
            name: "lastName",
            label: "Last Name",
            component: "input",
        },
        {
            name: "email",
            label: "Email",
            component: "input",
        },
        {
            name: "phone",
            label: "Phone Number",
            component: "input",
        },
        {
            name: "city",
            label: "City",
            component: "input",
        },
        {
            name: "body",
            label: "Tell us how we can help you succeed",
            component: "textarea",
        },
    ];

    return modalVisibility ? (
        <Portal>
            <Modal
                onModalClose={() => {
                    setModalVisibility(false);
                }}
                backdropClassName="bg-opacity-95 bg-black p-5 flex md:items-center justify-center"
                closeOnBackdropClick={false}
            >
                <button
                    className={styles["close-button"]}
                    type="button"
                    onClick={() => {
                        setModalVisibility(false);
                    }}
                >
                    <CloseIcon color="#fff" />
                </button>
                <ContactUsForm
                    className={styles["contact-form"]}
                    fields={fields as Field[]}
                    legend="Contact Us for a Free Consultation."
                    submitButtonMessage="Contact Us"
                />
            </Modal>
        </Portal>
    ) : (
        <></>
    );
}
