import React, { ReactElement } from "react";

export default function Users({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
            className={className}
        >
            <mask
                id="a33"
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#d9d9d9" d="M0 0h80v80H0z"></path>
            </mask>
            <g mask="url(#a33)">
                <path
                    fill="#000"
                    d="M74.852 61.882h-3.449v-7.54c0-2.968-1.122-5.695-3.208-7.78-2.085-2.085-4.812-3.208-7.78-3.208-1.604 0-3.208.32-4.732 1.043-.562.24-.802.962-.562 1.524.241.561.963.802 1.525.561 1.122-.561 2.406-.802 3.69-.802 4.731 0 8.662 3.85 8.662 8.662v7.54h-15.08V51.615c0-3.69-1.444-7.218-4.091-9.865a13.9 13.9 0 00-9.866-4.09c-1.844 0-3.69.4-5.374 1.042-1.684.722-3.208 1.685-4.49 3.048a13.9 13.9 0 00-4.091 9.866v10.186l-15.161-.001v-7.54c0-4.732 3.85-8.662 8.662-8.662 1.284 0 2.567.24 3.69.802.562.24 1.284 0 1.524-.562.24-.561 0-1.283-.562-1.524-1.443-.721-3.048-1.043-4.732-1.043-2.967 0-5.695 1.123-7.78 3.209-2.085 2.085-3.208 4.812-3.208 7.78v7.54H5.07c-.642 0-1.203.481-1.203 1.203 0 .722.481 1.203 1.203 1.203h69.861c.642 0 1.203-.481 1.203-1.203 0-.721-.561-1.123-1.283-1.123zm-23.26 0h-23.26V51.615c0-6.417 5.213-11.63 11.63-11.63s11.63 5.213 11.63 11.63z"
                ></path>
                <g fill="#dd1f26">
                    <path d="M39.961 34.61a9.687 9.687 0 009.706-9.705A9.687 9.687 0 0039.96 15.2a9.687 9.687 0 00-9.705 9.705 9.688 9.688 0 009.705 9.706zm0-17.164a7.36 7.36 0 017.38 7.38c0 4.09-3.29 7.379-7.38 7.379s-7.379-3.29-7.379-7.38c0-4.01 3.289-7.379 7.38-7.379zM19.509 41.509c4.25 0 7.78-3.45 7.78-7.78 0-4.331-3.449-7.78-7.78-7.78-4.251 0-7.78 3.448-7.78 7.78s3.449 7.78 7.78 7.78zm0-13.234c2.967 0 5.454 2.406 5.454 5.453a5.409 5.409 0 01-5.454 5.454c-3.048 0-5.454-2.406-5.454-5.454s2.406-5.453 5.454-5.453zM60.415 41.509c4.25 0 7.78-3.45 7.78-7.78 0-4.331-3.449-7.78-7.78-7.78-4.251 0-7.78 3.448-7.78 7.78s3.53 7.78 7.78 7.78zm0-13.234c2.968 0 5.454 2.406 5.454 5.453a5.409 5.409 0 01-5.454 5.454 5.409 5.409 0 01-5.454-5.454c0-3.047 2.486-5.453 5.454-5.453z"></path>
                </g>
            </g>
        </svg>
    );
}
