import React, { ReactElement } from "react";
// import styles from "./styles.module.scss";
import Cup from "./svgs/cup";
import Handshake from "./svgs/handshake";
import Speaker from "./svgs/speaker";
import Tag from "./svgs/tag";
import TrendDown from "./svgs/trendDown";
import Users from "./svgs/users";

export default function CompetitiveAdvantage(): ReactElement {
    const iconsClass = "h-10 w-10 md:w-20 md:h-20 mx-auto mb-8";
    const list = [
        {
            title: "Highly Qualified Lead Generation Experts",
            icon: <Cup className={iconsClass} />,
        },
        {
            title: "Guaranteed Pricing on Every Retainer",
            icon: <Tag className={iconsClass} />,
        },
        {
            title: "Exceptionally Low Fallout Rate",
            icon: <TrendDown className={iconsClass} />,
        },
        {
            title: "A Crystal-Clear Marketing Approach",
            icon: <Speaker className={iconsClass} />,
        },
        {
            title: "Streamlined Intake with Trusted Partners",
            icon: <Handshake className={iconsClass} />,
        },
        {
            title: "In-House Team of 15+ Digital Marketing Experts",
            icon: <Users className={iconsClass} />,
        },
    ];

    return (
        <section className="px-8 py-20 md:py-40 main-container">
            <h2 className=" text-xl md:text-4xl text-center mb-10 md:mb-20">
                Our Commitment to You
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-32">
                {list.map((item) => (
                    <div
                        key={item.title}
                        className="[&:not(:last-child)]:border-b [&:not(:last-child)]:md:border-transparent border-gray-200 pb-8"
                    >
                        {item.icon}
                        <p className="text-center md:text-xl">{item.title}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}
