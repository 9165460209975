import React, { ReactElement } from "react";
import DefaultLayout from "@layout/default";
import type { NextPageWithLayout } from "./_app";
import MetaTags from "@components/shared/metaTags";
import { GQLDomains } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import { GQLDomainQuery } from "@gql/types/gql";
import initialCall from "@helpers/initial-call";

import { getKeyWordsForMetaTags } from "@lib/sharedUtils";
import { GetStaticPropsContext } from "next";
import Hero from "@components/torts/hero";
import CompetitiveAdvantage from "@components/torts/competitiveAdvantage";
import Cases from "@components/torts/cases";
import Process from "@components/torts/process";
import HotTorts from "@components/torts/hotTorts";
import LowFalloutRates from "@components/torts/lowFalloutRates";
import ContactUsModal from "@components/torts/contactUsModal";

const Page: NextPageWithLayout = function Pg() {
    const { data: domainInfo } = useQuery<GQLDomainQuery, Error>({
        queryKey: ["domain"],
    });

    return (
        <>
            <MetaTags
                title={domainInfo?.domain?.title as string}
                description="Torts.com: Case Acquisition Experts"
                keyWords={
                    getKeyWordsForMetaTags(
                        domainInfo?.domain as GQLDomains,
                    ) as string
                }
            />
            <Hero />
            <CompetitiveAdvantage />
            <Cases />
            <Process />
            <HotTorts />
            <LowFalloutRates />
            <ContactUsModal />
        </>
    );
};

Page.getLayout = function getLayout(page: ReactElement) {
    return (
        <DefaultLayout
        //  pageType="home"
        >
            {page}
        </DefaultLayout>
    );
};

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
    return await initialCall("home", ctx);
};

export default Page;
