import React, { ReactElement } from "react";

export default function Tag({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
            className={className}
        >
            <mask
                id="mask0_70_108"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g mask="url(#mask0_70_108)">
                <path
                    fill="#000"
                    d="M66.15 44.21a18.268 18.268 0 00-9.31-15.91v-7.468a6.646 6.646 0 00-3.349-5.857l-14.303-8.41a6.622 6.622 0 00-6.886 0L18 14.978a6.643 6.643 0 00-3.349 5.857v47.054a6.799 6.799 0 006.791 6.792h28.606a6.8 6.8 0 006.792-6.792v-7.772a18.263 18.263 0 009.31-15.906zM54.88 67.888a4.837 4.837 0 01-4.829 4.83H21.442a4.833 4.833 0 01-4.829-4.83V20.833a4.665 4.665 0 012.381-4.164l14.304-8.414a4.707 4.707 0 014.896 0l14.304 8.414a4.67 4.67 0 012.384 4.164v6.507a18.26 18.26 0 100 33.736l-.001 6.812zm-6.987-7.382a16.299 16.299 0 1116.296-16.295A16.319 16.319 0 0147.89 60.507l.003-.001zM35.746 23.642A4.644 4.644 0 1031.103 19a4.646 4.646 0 004.643 4.642zm0-7.32a2.685 2.685 0 11-2.685 2.683 2.683 2.683 0 012.685-2.688v.004zm-15.958 20.42c0-.541.44-.98.982-.98h7.574a.981.981 0 010 1.962H20.77a.981.981 0 01-.982-.981zm0 5.32c0-.542.44-.981.982-.981h6.117a.981.981 0 010 1.962H20.77a.98.98 0 01-.982-.98zm0 5.319a.98.98 0 01.982-.981h6.117a.98.98 0 110 1.961H20.77a.98.98 0 01-.982-.977v-.003zm0 5.32c0-.543.44-.983.982-.983h7.908a.981.981 0 010 1.963H20.77a.981.981 0 01-.982-.98zm0 5.32c0-.541.44-.981.982-.981h9.843a.98.98 0 010 1.962H20.77a.98.98 0 01-.982-.98zm18.987 5.318c0 .541-.44.98-.981.98H20.77a.981.981 0 010-1.962h17.02a.98.98 0 01.985.982z"
                ></path>
            </g>
            <path
                fill="#DD1F26"
                d="M52.282 40.8a.981.981 0 01-.98-.982 3.404 3.404 0 00-2.434-3.254v6.758a5.37 5.37 0 010 10.564v1.254a.981.981 0 01-1.962 0v-1.253a5.383 5.383 0 01-4.393-5.283.981.981 0 011.962 0 3.407 3.407 0 002.433 3.254v-6.762a5.37 5.37 0 01-3.841-7.684 5.368 5.368 0 013.841-2.881v-1.254a.981.981 0 011.962 0v1.259a5.38 5.38 0 014.393 5.282c0 .542-.44.981-.98.981z"
            ></path>
            <path
                fill="#fff"
                d="M45.151 37.79a3.405 3.405 0 00-.678 2.028h.002a3.407 3.407 0 002.43 3.255v-6.508c-.7.209-1.316.638-1.754 1.224zM50.621 50.633a3.404 3.404 0 00.678-2.03 3.404 3.404 0 00-2.432-3.253v6.508a3.404 3.404 0 001.754-1.225z"
            ></path>
        </svg>
    );
}
