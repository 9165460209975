import React, { ReactElement } from "react";

export default function TrendDown({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
            className={className}
        >
            <mask
                id="a1"
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#d9d9d9" d="M0 0h80v80H0z"></path>
            </mask>
            <g mask="url(#a1)">
                <path
                    fill="#dd1f26"
                    d="M52.655 23.342l10.91 10.897h-3.696V36.3h7.202V29.1H65.01v3.682L52.654 20.426 42.361 30.732 20.429 8.8l-1.458 1.458 23.39 23.378z"
                ></path>
                <path
                    fill="#000"
                    d="M70.162 69.255V54.84H59.868v14.415h-2.061V38.362H47.513v30.893h-2.06v-24.71H35.145v24.71h-2.061V30.13H22.79v39.138H20.73V23.946H10.436v45.31H8.375v2.06h63.847v-2.06zM61.929 56.9h6.184v12.355H61.93zM49.563 40.423h6.183v28.832h-6.183zm-12.356 6.183h6.184v22.66h-6.184zM24.852 32.178h6.184v37.077h-6.184zm-12.355-6.171h6.183v43.248h-6.183z"
                ></path>
            </g>
        </svg>
    );
}
