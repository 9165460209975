import React, { ReactElement } from "react";

export default function Hammer({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
            className={className}
        >
            <mask
                id="mask0_84_2035"
                style={{ maskType: "alpha" }}
                width="40"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H40V40H0z"></path>
            </mask>
            <g mask="url(#mask0_84_2035)">
                <mask
                    id="mask1_84_2035"
                    style={{ maskType: "alpha" }}
                    width="39"
                    height="37"
                    x="0"
                    y="2"
                    maskUnits="userSpaceOnUse"
                >
                    <path
                        fill="#fff"
                        d="M0.666 2.001H38.775V38.748999999999995H0.666z"
                    ></path>
                </mask>
                <g fill="#fff" mask="url(#mask1_84_2035)">
                    <path d="M15.037 32.626a.486.486 0 01-.498-.48v-1.06a.643.643 0 00-.657-.633H6.36a.643.643 0 00-.656.634v1.058c0 .268-.221.48-.498.48a.486.486 0 01-.498-.48v-1.059c0-.875.74-1.593 1.652-1.593h7.518c.908 0 1.652.713 1.652 1.594v1.058a.485.485 0 01-.492.48z"></path>
                    <path d="M16.647 35.05H3.71a.486.486 0 01-.498-.48v-1.35c0-.857.723-1.554 1.611-1.554h10.704c.888 0 1.611.697 1.611 1.553v1.351a.482.482 0 01-.492.48zm-12.438-.96h11.94v-.87a.607.607 0 00-.616-.594H4.824a.607.607 0 00-.615.593v.871zM33.78 35.05a2.298 2.298 0 01-1.605-.643L18.289 21.022a.466.466 0 010-.678.509.509 0 01.703 0L32.877 33.73c.487.47 1.324.47 1.811 0a1.198 1.198 0 000-1.742L20.772 18.573a.466.466 0 010-.678.509.509 0 01.703 0L35.386 31.31a2.128 2.128 0 010 3.097 2.29 2.29 0 01-1.606.643z"></path>
                    <path d="M15.13 24.618a.494.494 0 01-.35-.138.47.47 0 01-.01-.678l9.796-9.639a.508.508 0 01.703-.005c.195.188.2.49.005.678l-9.79 9.639a.49.49 0 01-.354.143zM9.484 19.29a.494.494 0 01-.349-.138.466.466 0 01-.005-.678l9.796-9.639a.508.508 0 01.703-.004c.195.188.2.49.005.677l-9.79 9.64a.514.514 0 01-.36.143z"></path>
                    <path d="M14.101 27.515a.976.976 0 01-.667-.263L6.05 20.28a.902.902 0 01-.015-1.3l1.452-1.431a.98.98 0 011.35-.015l7.378 6.972c.375.357.38.94.016 1.302l-1.452 1.43a.953.953 0 01-.678.277zM6.78 19.622l7.322 6.918 1.396-1.376-7.322-6.918-1.396 1.377zM26.11 15.927a.976.976 0 01-.667-.263l-7.379-6.967a.902.902 0 01-.015-1.3L19.5 5.965a.98.98 0 011.35-.015l7.378 6.972c.375.357.38.94.015 1.302l-1.452 1.43a.96.96 0 01-.682.272zm-7.322-7.888l7.322 6.917 1.396-1.375-7.323-6.918-1.395 1.376z"></path>
                </g>
            </g>
        </svg>
    );
}
